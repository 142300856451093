<template>
  <div>
    <!-- <div class="navbar" :class="[isShow || isShowSync ? 'navbar-show' : 'navbar-hidden']"> -->
    <div class="navbar">
      <div class="center">
        <div class="left">
          <div class="logo">
            <img src="../../assets/img/logo.png" @click="$router.push('/')" />
          </div>
        </div>
        <div class="right">
          <ul class="menu" @mouseover="isSearchShow = false">
            <li class="menu_item" @click="$router.push('/')">
              <span class="menu_item_span">网站首页</span>
            </li>
            <li class="mar_li menu_item" @click="$router.push('/about')">
              <span class="menu_item_span">公司简介</span>
            </li>
            <li
              class="mar_li menu_item menu_item1"
              @mouseover="handleUlMouseOver(1, 'one')"
              @mouseout="handleUlMouseOver(2, 'one')"
            >
              <span
                class="menu_item_span"
                @click="jumpReload({path: '/product', query: {idx: 0}})"
              >产品中心</span>
              <ul class="menu_item_hover_ul menu_item1_hover_ul" ref="one_ul">
                <!-- <li class="box_li" @click="$router.push({path: '/product', query: {idx: 2}})">键鼠同步器</li> -->
                <li class="box_li" @click="jumpReload({path: '/product', query: {idx: 2}})">电脑同步</li>
                <li class="box_li" @click="jumpReload({path: '/product', query: {idx: 3}})">手机同步</li>
                <li class="box_li" @click="jumpReload({path: '/product', query: {idx: 4}})">智能控制</li>
                <li class="box_li" @click="jumpReload({path: '/product', query: {idx: 5}})">KM记录器</li>
                <li class="box_li" @click="jumpReload({path: '/product', query: {idx: 6}})">数据采集转换</li>
              </ul>
            </li>
            <li
              class="mar_li menu_item menu_item2"
              @mouseover="handleUlMouseOver(1, 'two')"
              @mouseout="handleUlMouseOver(2, 'two')"
            >
              <span
                class="menu_item_span menu_item_span2"
                @click="jumpReload({path: '/download', query: {idx: 8}})"
              >资料下载</span>
              <ul class="menu_item_hover_ul menu_item2_hover_ul" ref="two_ul">
                <li class="box_li" @click="jumpReload({path: '/download', query: {idx: 0}})">同步控制器</li>
                <li class="box_li" @click="jumpReload({path: '/download', query: {idx: 1}})">KM记录器</li>
                <li class="box_li" @click="jumpReload({path: '/download', query: {idx: 2}})">软件下载</li>
                <li class="box_li" @click="jumpReload({path: '/download', query: {idx: 3}})">手机同步控制器</li>
              </ul>
            </li>
            <li
              class="mar_li menu_item menu_item3"
              @mouseover="handleUlMouseOver(1, 'three')"
              @mouseout="handleUlMouseOver(2, 'three')"
            >
              <span
                class="menu_item_span"
                @click="jumpReload({path: '/news', query: {idx: 0}})"
              >新闻公告</span>
              <ul class="menu_item_hover_ul menu_item3_hover_ul" ref="three_ul">
                <li class="box_li" @click="jumpReload({path: '/news', query: {idx: 0}})">新闻动态</li>
                <li class="box_li" @click="jumpReload({path: '/news', query: {idx: 1}})">常见问题</li>
              </ul>
            </li>
            <li class="mar_li menu_item" @click="$router.push('/contact')">
              <span class="menu_item_span">联系我们</span>
            </li>
          </ul>
          <div class="bg_div" ref="bg_div"></div>
        </div>
      </div>
      <div class="search">
        <div class="input_div">
          <i
            @click="isSearchShow = !isSearchShow"
            class="el-icon-search my-search"
            style="width: 40px;font-size: 20px;cursor: pointer;"
          ></i>
          <div class="input_div_box" v-show="isSearchShow">
            <input v-model="search" class="search_ipt" type="text" placeholder="请输入想要查找的内容~" />
            <i
              @click="handleSearchClickIpt"
              class="el-icon-search my-search"
              style="width: 40px;font-size: 20px;cursor: pointer;margin-top: 14px;color: #fff;"
            ></i>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-navbar" :class="[isShow ? 'navbar-show' : 'navbar-hidden']">
      <img
        v-show="!isSearchShow2"
        class="show_img"
        @click="$emit('handleSyncDrawer')"
        src="../../assets/img/icon_menu_hover.png"
      />
      <img v-show="!isSearchShow2" class="logo_img" src="../../assets/img/logo.png" alt />
      <el-input
        v-model="search"
        v-show="isSearchShow2"
        suffix-icon="el-icon-search"
        size="mini"
        @blur="handleSearchClickIpt"
        placeholder="请输入想要查找的内容~"
        style="width: 80%;"
      ></el-input>
      <span @click="isSearchShow2 = !isSearchShow2" v-show="isSearchShow2" class="cancel_span">取消</span>
      <i
        v-show="!isSearchShow2"
        @click="isSearchShow2 = !isSearchShow2"
        class="el-icon-search my-search-mobile"
        style="width: 40px;font-size: 18px;cursor: pointer;color: #5f6464;"
      ></i>
    </div>
  </div>
</template>

<script>
import { routerList, info } from "@/data/searchData.js";

export default {
  props: ["isShowSync"],
  data() {
    return {
      isShow: true,
      isSearchShow: false,
      isSearchShow2: false,
      routerList,
      info,
      search: ""
    };
  },
  mounted() {
    //给页面绑定滑轮滚动事件
    if (document.addEventListener) {
      document.addEventListener("DOMMouseScroll", this.scrollFunc, false);
    } //W3C
    //滚动滑轮触发scrollFunc方法
    window.onmousewheel = document.onmousewheel = this.scrollFunc; //IE/Opera/Chrome
  },
  methods: {
    handleSearchClickIpt() {
      const idx = this.routerList.indexOf(this.search);
      console.log("当前索引", idx);
      if (idx != -1) {
        this.search = "";
        this.$router.push(this.info[idx].src);
      }
    },
    handleUlMouseOver(type, str) {
      if (str == "one") {
        this.$refs["one_ul"].style.display = "none";
      }
      if (type == 1) {
        this.$refs["bg_div"].style.opacity = 1;
        this.$refs["bg_div"].style.height = "210px";
        if (str == "one") {
          this.$refs["one_ul"].style.display = "block";
        } else if (str == "two") {
          this.$refs["two_ul"].style.display = "block";
        } else {
          this.$refs["three_ul"].style.display = "block";
        }
      } else {
        this.$refs["bg_div"].style.opacity = 0;
        this.$refs["bg_div"].style.height = "0px";
        if (str == "one") {
          this.$refs["one_ul"].style.display = "none";
        } else if (str == "two") {
          this.$refs["two_ul"].style.display = "none";
        } else {
          this.$refs["three_ul"].style.display = "none";
        }
      }
    },
    jumpReload(params) {
      this.$refs["one_ul"].style.display = "none";
      this.$refs["two_ul"].style.display = "none";
      this.$refs["three_ul"].style.display = "none";
      this.$refs["bg_div"].style.opacity = 0;
      this.$refs["bg_div"].style.height = "0px";
      this.$router.push(params);
      // if (typeof params == "string") {
      //   if (this.$route.path != params) {
      //     this.$router.push(params);
      //   }
      // } else {
      //   if (this.$route.path != params.path) {
      //     this.$router.push(params);
      //   }
      // }
    },
    /** 鼠标滚轮事件 向上触发放大，向下 缩小*/
    scrollFunc(e) {
      e = e || window.event;
      if (e.wheelDelta) {
        //IE/Opera/Chrome
        console.log(e.wheelDelta); // +120表示向上；-120表示向下
        if (e.wheelDelta == "+120") {
          this.isShow = true;
          setTimeout(() => {
            this.$emit("handleSyncSlider", false);
          }, 0);
        } else {
          this.isShow = false;
          this.isSearchShow = false;
          setTimeout(() => {
            this.$emit("handleSyncSlider", true);
          }, 0);
        }
      } else if (e.detail) {
        //Firefox滑轮事件
        console.log(e.detail); // +3表示向上；-3表示向下
        if (e.detail == "+3") {
          this.isShow = true;
        } else {
          this.isShow = false;
          this.isSearchShow = false;
        }
      }
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    }
  }
};
</script>

<style lang="less" scoped>
.navbar {
  // height: 72px;
  height: 60px;
  background-color: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  & > div {
    // height: 72px;
    height: 60px;
  }
  .center {
    width: 80%;
    // height: 72px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    .left {
      display: flex;
      align-items: center;
      .logo {
        width: 168px;
        & > img {
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
      }
    }
  }

  .right {
    .bg_div {
      width: 100vw;
      height: 0;
      background-color: #fff;
      position: fixed;
      padding-bottom: 30px;
      left: 0;
      // top: 72px;
      top: 60px;
      z-index: 10;
      // transition: all 0.2s linear;
      opacity: 0;
      box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.1);
    }

    .menu {
      display: flex;

      .mar_li {
        margin-left: 72px;
      }
      .menu_item {
        // line-height: 72px;
        line-height: 60px;
        font-weight: bold;
        font-family: "PingFangSC-Regular", "helvetica neue", tahoma,
          "PingFang SC", "microsoft yahei", arial, "hiragino sans gb",
          sans-serif;
        cursor: pointer;
        position: relative;

        .menu_item_hover_ul {
          position: absolute;
          width: 200px;
          left: 0;
          // top: 72px;
          top: 60px;
          z-index: 100;
          padding-left: 0;
          height: 210px;
          transition: all 0.1s linear;
          display: none;
          .box_li {
            font-weight: 400;
            font-size: 14px;
            color: #2b2e2e;
            opacity: 0.7;
            padding: 13px 0;
            height: 13px;
            line-height: 13px;
          }
          .box_li:hover {
            opacity: 1;
          }
        }
        .menu_item2_hover_ul {
          // width: 64px;
          width: 100px;
          // margin-left: -14px;
          margin-left: -17px;
          text-align: center;
        }
        .menu_item1_hover_ul {
          width: 112px;
          text-align: center;
          left: -24px;
        }
        .menu_item_span:after {
          display: block;
          content: "";
          height: 4px;
          width: 100%;
          background: #194ac3;
          position: absolute;
          // bottom: 19px;
          bottom: 10px;
          left: 0px;
          opacity: 0;
          -webkit-transition: transform 0.2s ease-out, opacity 0.2s ease-out;
          transform-origin: 50% 0%;
          transform: scale(0.01, 1);
        }
      }

      .menu_item1:hover {
        .menu_item1_hover_ul {
          display: block;
        }
      }

      .menu_item2:hover {
        .menu_item2_hover_ul {
          display: block;
        }
      }
      .menu_item3:hover {
        .menu_item3_hover_ul {
          display: block;
        }
      }

      .menu_item:hover {
        .menu_item_span:after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
  // .right:hover .bg_div {
  //   height: 210px;
  //   opacity: 1;
  // }
}
.mobile-navbar {
  display: none;
}
.navbar-show {
  transition: opacity linear 0.2s;
  opacity: 1;
}
.navbar-hidden {
  transition: opacity linear 0.2s;
  opacity: 0;
}
.search {
  position: fixed;
  top: 20px;
  right: 32px;
  .input_div {
    width: 100%;
    height: 100%;
    position: relative;
    .input_div_box {
      width: 300px;
      height: 50px;
      position: absolute;
      // background-color: #00a794;
      background-color: #0066cc;
      display: flex;
      // top: 46px;
      top: 40px;
      right: 10px;
      .search_ipt {
        outline: none;
        border: none;
        width: 300px;
        height: 50px;
        background-color: transparent;
        font-size: 15px;
        margin-left: 20px;
        color: #fff;
      }
      input::input-placeholder {
        color: #eee;
        opacity: 0.7;
      }
      input::-webkit-input-placeholder {
        //兼容WebKit browsers（Chrome的内核）
        color: #eee;
        opacity: 0.7;
      }
      input::-moz-placeholder {
        //Mozilla Firefox 4 to 18
        color: #eee;
        opacity: 0.7;
      }
      input::-moz-placeholder {
        //Mozilla Firefox 19+
        color: #eee;
        opacity: 0.7;
      }
      input::-ms-input-placeholder {
        //Internet Explorer 10+
        color: #eee;
        opacity: 0.7;
      }

      &:before {
        position: absolute;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent #0066cc transparent;
        content: "";
        right: 10px;
        top: -10px;
        transition: 0.3s;
        -moz-transition: 0.3s;
        -ms-transition: 0.3s;
        -webkit-transition: 0.3s;
      }
    }
  }
}

@media (max-width: 1500px) {
  .navbar {
    .center {
      width: 1000px;
    }
  }
}
@media (max-width: 1300px) {
  .navbar {
    .center {
      width: 69%;
    }
    .right {
      .menu {
        .mar_li {
          margin-left: 22px;
        }
        .menu_item {
          font-size: 15px;
        }
      }
    }
  }
}
@media (max-width: 1050px) {
  .navbar {
    .center {
      width: 70%;
    }
    .right {
      .menu {
        margin-left: -150px;
        .mar_li {
          margin-left: 20px;
        }
        .menu_item {
          font-size: 13px;
        }
      }
    }
    .my-search {
      font-size: 13px !important;
      cursor: pointer;
    }
  }
}
@media (max-width: 884px) {
  .navbar {
    .center {
      width: 80%;
      margin: 0 0 0 30px;
    }
  }
}
@media (max-width: 750px) {
  .navbar {
    display: none;
  }
  .mobile-navbar {
    display: block;
    width: 100%;
    height: 56px;
    line-height: 56px;
    background-color: #fff;
    position: relative;
    text-align: center;
    .cancel_span {
      cursor: pointer;
      color: #409eff;
      margin-left: 10px;
      font-size: 14px;
    }
    .show_img {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
    .my-search-mobile {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
    .logo_img {
      width: 137px;
      vertical-align: middle;
    }
  }
}
</style>