<template>
  <div id="app">
    <div class="nav">
      <NavBar
        :isShowSync="isBool"
        @handleSyncDrawer="handleSyncDrawer"
        @handleSyncSlider="handleSyncSlider"
      />
    </div>
    <div class="content">
      <router-view></router-view>
    </div>
    <div class="slider" v-if="isSyncSlider">
      <div class="top" @mouseover="handleErWeiMa(1)" @mouseout="calcelErWeiMa">
        <img src="./assets/img/slider1.png" alt />
        <p>商务合作</p>
      </div>
      <div class="middle" @mouseover="handleErWeiMa(2)" @mouseout="calcelErWeiMa">
        <img src="./assets/img/slider2.png" alt />
        <p>业务咨询</p>
      </div>
      <div class="footer" @mouseover="handleErWeiMa(3)" @mouseout="calcelErWeiMa">
        <img src="./assets/img/slider3.png" alt />
        <p>售后服务</p>
      </div>
    </div>
    <!-- 商务合作二维码 -->
    <div class="one_erweima" v-if="oneImgSync" @click="calcelErWeiMa">
      <img class="one_erweima_img" src="./assets/img/swhz11.jpg" alt />
    </div>
    <!-- 商务合作二维码 -->
    <div class="one_erweima two_erweima" v-if="twoImgSync" @click="calcelErWeiMa">
      <img class="one_erweima_img" src="./assets/img/ywzx11.jpg" alt />
    </div>
    <!-- 商务合作二维码 -->
    <div class="one_erweima two_erweima three_erweima" v-if="threeImgSync" @click="calcelErWeiMa">
      <img class="one_erweima_img" src="./assets/img/shfw11.jpg" alt />
    </div>
    <!-- 返回顶部 -->
    <!-- <div class="go_top"> -->
    <a href="#" class="go_top" @click="isBool = true;">
      <img src="./assets/img/gotop.png" alt />
    </a>
    <!-- </div> -->
    <!-- 抽屉 -->
    <el-drawer size="48%" :visible.sync="drawer" :direction="direction">
      <div class="navbar_div">
        <ul class="navbar_ul">
          <li class="nvabar_ul_li" @click="drawerClick('/')">网站首页</li>
          <li class="nvabar_ul_li" @click="drawerClick('/about')">公司简介</li>
          <li class="nvabar_ul_li" @click="drawerClick({path: '/product', query: {idx: 0}})">产品中心</li>
          <li class="nvabar_ul_li" @click="drawerClick({path: '/download', query: {idx: 8}})">资料下载</li>
          <li class="nvabar_ul_li" @click="drawerClick({path: '/news', query: {idx: 0}})">新闻公告</li>
          <li class="nvabar_ul_li" @click="drawerClick('/contact')">联系我们</li>
        </ul>
        <!-- <div class="mobile-search">
          <el-input v-model="search" suffix-icon="el-icon-search" size="mini" placeholder="请输入想要查找的内容~" style="width: 80%;margin-top: 50px;"></el-input>
        </div>-->
      </div>
    </el-drawer>
  </div>
</template>

<script>
import NavBar from "@/layout/navbar/index.vue";

export default {
  components: {
    NavBar
  },
  data() {
    return {
      scrollTop: 0,
      isBool: false,
      oneImgSync: false,
      twoImgSync: false,
      threeImgSync: false,
      drawer: false,
      isSyncSlider: false,
      direction: "ltr",
      search: ""
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  watch: {
    $route: {
      handler(newval) {
        if (newval.name != "home") {
          this.isSyncSlider = true;
        } else {
          if (window.pageYOffset >= 500 && this.$route.name == "home") {
            this.isSyncSlider = true;
          }
          if (window.pageYOffset < 500 && this.$route.name == "home") {
            this.isSyncSlider = false;
          }
        }
      }
    }
  },
  methods: {
    drawerClick(params) {
      if (typeof params == "string") {
        if (this.$route.path != params) {
          this.$router.push(params);
        }
      } else {
        if (this.$route.path != params.path) {
          this.$router.push(params);
        }
      }
      this.drawer = false;
    },
    handleScroll() {
      console.log("页面滚动距离", window.pageYOffset, this.$route.name);
      if (window.pageYOffset >= 500 && this.$route.name == "home") {
        this.isSyncSlider = true;
      }
      if (window.pageYOffset < 500 && this.$route.name == "home") {
        this.isSyncSlider = false;
      }
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (this.scrollTop == 0) {
        this.isBool = true;
      }
    },
    calcelErWeiMa() {
      this.oneImgSync = this.twoImgSync = this.threeImgSync = false;
    },
    handleErWeiMa(type) {
      this.oneImgSync = this.twoImgSync = this.threeImgSync = false;
      if (type == 1) {
        this.oneImgSync = true;
      } else if (type == 2) {
        this.twoImgSync = true;
      } else {
        this.threeImgSync = true;
      }
    },
    handleSyncDrawer() {
      this.drawer = true;
    },
    handleSyncSlider(bool) {
      if (bool) {
        this.isBool = false;
      }
      // this.calcelErWeiMa();
      // this.isSyncSlider = bool;
      // if(!bool){
      //   this.calcelErWeiMa();
      // }
    }
  }
};
</script>

<style lang="less">
#app {
  box-sizing: border-box;
  // padding-top: 72px;
  padding-top: 60px;
  .one_erweima {
    width: 141px;
    height: 158px;
    z-index: 10;
    position: fixed;
    right: 100px;
    transform: translateY(-50%);
    top: 46%;
    box-shadow: 0px 2px 26px 0px rgba(0, 0, 0, 0.1);
    &::before {
      position: absolute;
      border-style: solid;
      border-width: 10px 10px 10px 15px;
      border-color: transparent transparent transparent #fff;
      content: "";
      top: 16px;
      right: -25px;
      transition: 0.3s;
      -moz-transition: 0.3s;
      -ms-transition: 0.3s;
      -webkit-transition: 0.3s;
    }
    .one_erweima_img {
      width: 141px;
      height: 158px;
    }
  }
  .two_erweima {
    width: 282px;
    top: 55%;
    .one_erweima_img {
      width: 100%;
    }
  }
  .three_erweima {
    top: 65%;
  }
  .go_top {
    width: 55px;
    height: 55px;
    background-color: #fff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    position: fixed;
    right: 10px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 100px;
    text-align: center;
    & > img {
      width: 40%;
    }
  }
  .nav {
    width: 100%;
    // height: 72px;
    height: 60px;
    position: fixed;
    left: 0;
    top: 0;
    // background-color: #fff;
    z-index: 200;
  }

  .slider {
    width: 63px;
    height: 260px;
    // background-color: #000;
    position: fixed;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    text-align: center;
    box-sizing: border-box;
    padding: 19px 0;
    z-index: 100;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    cursor: pointer;
    img {
      width: 28px;
    }
    p {
      text-align: center;
      margin-top: 10px;
      font-size: 12px;
      color: #0066cc;
    }
    .middle,
    .footer {
      box-sizing: border-box;
      padding-top: 20px;
    }
  }
}

.el-drawer__header > :first-child {
  flex: 0 !important;
}

@media (max-width: 750px) {
  #app {
    padding-top: 56px;
    .slider {
      display: none;
    }
    .nav {
      height: 56px !important;
    }
    .navbar_div {
      box-sizing: border-box;
      // padding-left: 10px;
      padding-top: 35px;
      .navbar_ul {
        .nvabar_ul_li {
          color: #2a2e2e;
          font-weight: normal;
          // height: 72px;
          // line-height: 72px;
          height: 60px;
          line-height: 60px;
          font-size: 16px;
          cursor: pointer;
          text-align: center;
          user-select: none;
        }
      }
      .mobile-search {
        margin-left: 40px;
      }
    }
  }
}
</style>
