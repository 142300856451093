import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.prototype.$key = '4fdfcee33f7226b974fa4bd79dc01eb4';
Vue.prototype.$position = [118.16660 , 39.69325];


// 引入vant
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

// 全局注册底栏
import Footer from '@/layout/footer/index.vue';
Vue.component('Footer', Footer)

// 判断pc还是移动端插件
import VueDeviceDetector from 'vue-device-detector'
Vue.use(VueDeviceDetector)

// 引入样式文件
import '@/styles/index.less' // global css

Vue.config.productionTip = false

// 跳转新页面置顶
// 跳转后返回顶部
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
