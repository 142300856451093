export const routerList = [
  '首页', 
  '公司简介', 
  '产品中心', 
  '资料下载',
  '新闻公告',
  '联系我们',
  '电脑同步',
  '手机同步',
  '智能控制',
  'KM记录器',
  '数据采集转换',
  '手机同步器下载',
  '同步器下载',
  '控制器下载',
  '软件下载',
  '新闻动态', 
  '常见问题'
]

export const info = [
  {
    name: '网站首页',
    src: '/'
  },
  {
    name: '公司简介',
    src: '/about'
  },
  {
    name: '产品中心',
    src: {path: '/product', query: {idx: 0}}
  },
  {
    name: '资料下载',
    src: {path: '/download', query: {idx: 8}}
  },
  {
    name: '新闻公告',
    src: {path: '/news', query: {idx: 0}}
  },
  {
    name: '联系我们',
    src: '/contact'
  },
  {
    name: '电脑同步',
    src: {path: '/product', query: {idx: 2}}
  },
  {
    name: '手机同步',
    src: {path: '/product', query: {idx: 3}}
  },
  {
    name: '智能控制',
    src: {path: '/product', query: {idx: 4}}
  },
  {
    name: 'KM记录器',
    src: {path: '/product', query: {idx: 5}}
  },
  {
    name: '数据采集转换',
    src: {path: '/product', query: {idx: 6}}
  },
  {
    name: '手机同步器下载',
    src: {path: '/download', query: {idx: 3}}
  },
  {
    name: '同步器下载',
    src: {path: '/download', query: {idx: 0}}
  },
  {
    name: '控制器下载',
    src: {path: '/download', query: {idx: 1}}
  },
  {
    name: '软件下载',
    src: {path: '/download', query: {idx: 2}}
  },
  {
    name: '新闻动态',
    src: {path: '/news', query: {idx: 0}}
  },
  {
    name: '常见问题',
    src: {path: '/news', query: {idx: 1}}
  }
]