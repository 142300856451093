<template>
  <div class="footer">
    <div class="top">
      <div class="left">
        <div>
          <img class="logo" src="../../assets/img/logo.png" alt />
        </div>
        <div class="iphone">17317058000</div>
        <div style="font-size: 16px;">免费咨询热线</div>
        <div class="lianxi">
          <div>
            <img class="one_img" src="../../assets/img/weixin1.png" alt />
            <p>微信</p>
          </div>
          <div>
            <img class="two_img" src="../../assets/img/douyin1.png" alt />
            <p class="two_p">抖音</p>
          </div>
          <!-- <img
            ref="weixinImg"
            @click="handleSyncBig(1)"
            class="big_img"
            src="../../assets/img/weixin1.png"
            alt
          />
          <img
            @click="handleSyncErWeiMa(1)"
            class="small_img m_left"
            src="../../assets/img/wx.png"
            alt
          />
          <img
            @click="handleSyncErWeiMa(2)"
            class="small_img m_left"
            src="../../assets/img/qq.png"
            alt
          />
          <img
            ref="douyinImg"
            @click="handleSyncBig(2)"
            class="big_img m_left"
            src="../../assets/img/douyin1.png"
            alt
          />-->
        </div>
      </div>
      <div class="right">
        <div class="zhedie">
          <el-collapse v-model="activeNames" @change="handleChange" style="background: #333333;">
            <el-collapse-item title="产品中心" name="1">
              <div class="zhedie_div" @click="$router.push({path: '/product', query: {idx: 2}})">电脑同步</div>
              <div class="zhedie_div" @click="$router.push({path: '/product', query: {idx: 3}})">手机同步</div>
              <div class="zhedie_div" @click="$router.push({path: '/product', query: {idx: 4}})">智能控制</div>
              <div class="zhedie_div" @click="$router.push({path: '/product', query: {idx: 5}})">KM记录器</div>
              <div class="zhedie_div last_zhedie_div" @click="$router.push({path: '/product', query: {idx: 6}})">数据采集转换</div>
            </el-collapse-item>
            <el-collapse-item title="资料下载" name="2">
              <div class="zhedie_div" @click="$router.push({path: '/download', query: {idx: 0}})">同步器控制器</div>
              <div class="zhedie_div" @click="$router.push({path: '/download', query: {idx: 1}})">KM记录器</div>
              <div class="zhedie_div" @click="$router.push({path: '/download', query: {idx: 2}})">软件下载</div>
              <div class="zhedie_div last_zhedie_div" @click="$router.push({path: '/download', query: {idx: 3}})">手机同步控制器</div>
            </el-collapse-item>
            <el-collapse-item title="新闻公告" name="3">
              <div class="zhedie_div" @click="$router.push({path: '/news', query: {idx: 0}})">新闻动态</div>
              <div class="zhedie_div last_zhedie_div" @click="$router.push({path: '/news', query: {idx: 1}})">常见问题</div>
            </el-collapse-item>
            <el-collapse-item title="关于我们" name="4">
              <div class="zhedie_div" @click="$router.push('/about')">公司简介</div>
              <div class="zhedie_div last_zhedie_div" @click="$router.push('/contact')">联系我们</div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <ul class="right_ul">
          <li class="one-li" @click="$router.push({path: '/product', query: {idx: 0}})">产品中心</li>
          <li @click="$router.push({path: '/product', query: {idx: 2}})">电脑同步</li>
          <li @click="$router.push({path: '/product', query: {idx: 3}})">手机同步</li>
          <li @click="$router.push({path: '/product', query: {idx: 4}})">智能控制</li>
          <li @click="$router.push({path: '/product', query: {idx: 5}})">KM记录器</li>
          <li @click="$router.push({path: '/product', query: {idx: 6}})">数据采集转换</li>
        </ul>
        <ul>
          <li class="one-li">资料下载</li>
          <li @click="$router.push({path: '/download', query: {idx: 0}})">同步控制器</li>
          <li @click="$router.push({path: '/download', query: {idx: 1}})">KM记录器</li>
          <li @click="$router.push({path: '/download', query: {idx: 2}})">软件下载</li>
          <li @click="$router.push({path: '/download', query: {idx: 3}})">手机同步控制器</li>
        </ul>
        <ul>
          <li class="one-li" @click="$router.push({path: '/news', query: {idx: 0}})">新闻公告</li>
          <li @click="$router.push({path: '/news', query: {idx: 0}})">新闻动态</li>
          <li @click="$router.push({path: '/news', query: {idx: 1}})">常见问题</li>
        </ul>
        <ul>
          <li class="one-li">关于我们</li>
          <li @click="$router.push('/about')">公司简介</li>
          <li @click="$router.push('/contact')">联系我们</li>
        </ul>
      </div>
    </div>
    <div class="bottom">
      <div class="center">
        <span>唐山数通科技有限公司</span>
        <span>备案号：冀ICP备19012341号-1</span>
        <span>地址：河北省唐山市高新技术产业开发区科智街8号（亨达科技园B座）</span>
        <span>联系电话：17317058000</span>
        <img class="lz" src="../../assets/img/lz.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeNames: []
    };
  },
  methods: {
    handleChange(val) {
      console.log(val);
    },
    handleSyncBig(type) {
      if (type == 1) {
        this.$refs["weixinImg"].style.opacity = 0;
      } else {
        this.$refs["douyinImg"].style.opacity = 0;
      }
    },
    handleSyncErWeiMa(type) {
      if (type == 1) {
        this.$refs["weixinImg"].style.opacity = 1;
      } else {
        this.$refs["douyinImg"].style.opacity = 1;
      }
    }
  }
};
</script>

<style lang="less">
.footer {
  width: 100%;
  overflow: hidden;
  .top {
    // height: 392px;
    background-color: #333333;
    display: flex;
    & > div {
      width: 50%;
      // height: 392px;
      box-sizing: border-box;
      padding-top: 70px;
    }
    .left {
      text-align: center;
      color: #fff;
      .logo {
        width: 270px;
      }
      .iphone {
        font-size: 44px;
        margin-top: 64px;
        margin-bottom: 14px;
      }
      .lianxi {
        width: 100%;
        // width: 951.5px;
        height: 150px;
        margin-top: 36px;
        margin-bottom: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        .m_left {
          margin-left: 30px;
        }
        p {
          margin-top: 5px;
          font-size: 12px;
          color: #999999;
        }
        img {
          width: 100px;
          height: 100px;
          cursor: pointer;
        }
        .two_img {
          margin-left: 20px;
        }
        .two_p {
          margin-left: 20px;
        }
        .small_img {
          width: 28px;
          height: 28px;
          // width: 15px;
          // height: 15px;
        }
        .big_img {
          width: 150px;
          opacity: 0;
          // width: 71px;
          // height: 71px;
        }
      }
    }
    .right {
      text-align: left;
      display: flex;
      .zhedie {
        display: none;
      }
      ul {
        margin-right: 88px;
        li {
          font-size: 12px;
          color: #999999;
          margin-bottom: 18px;
          cursor: pointer;
        }
        .one-li {
          font-size: 20px;
          color: #fff;
          margin-bottom: 36px;
        }
      }
    }
  }
  .bottom {
    height: 102px;
    font-size: 14px;
    display: flex;
    align-items: center;
    background-color: #000000;
    color: #fff;
    .center {
      width: 60%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .lz {
        width: 30px;
        height: 30px;
      }
    }
  }
}
@media (max-width: 1700px) {
  .footer {
    .top {
      .left {
        width: 40%;
      }
      .right {
        width: 60%;
      }
    }
    .bottom {
      .center {
        width: 70%;
      }
    }
  }
}
@media (max-width: 1500px) {
  .footer {
    .top {
      .left {
        width: 35%;
      }
      .right {
        width: 65%;
      }
    }
    .bottom {
      .center {
        width: 80%;
      }
    }
  }
}
@media (max-width: 1360px) {
  .footer {
    .top {
      .left {
        // width: calc(100% - 872px);
        .iphone {
          font-size: 35px;
        }
      }
      .right {
        // width: 872px;
        ul {
          margin-right: 60px;
          li {
            font-size: 12px;
          }
          .one-li {
            font-size: 16px;
          }
        }
      }
    }
    .bottom {
      .center {
        width: 95%;
        font-size: 12px;
      }
    }
  }
}
@media (max-width: 1080px) {
  .footer {
    .top {
      .left {
        .iphone {
          font-size: 28px;
        }
        .logo {
          width: 230px;
        }
      }
      .right {
        // width: 872px;
        ul {
          margin-right: 30px;
          li {
            font-size: 12px;
          }
          .one-li {
            font-size: 16px;
          }
        }
      }
    }
    .bottom {
      .center {
        width: 98%;
        font-size: 10px;
      }
    }
  }
}
@media (max-width: 970px) {
  .footer {
    .top {
      .right {
        ul {
          margin-right: 10px;
        }
      }
    }
    .bottom {
      height: auto;
      display: block;
      text-align: center;
      .center {
        display: block;
        box-sizing: border-box;
        padding-top: 15px;
        & > span {
          display: block;
          text-align: center;
          margin-bottom: 15px;
        }
        .lz {
          margin-bottom: 15px;
        }
      }
    }
  }
}
@media (max-width: 750px) {
  .footer {
    .top {
      display: block;
      height: auto;
      text-align: center;
      .left,
      .right {
        height: auto;
        width: 100%;
      }
      .left {
        padding-top: 40px;
        .lianxi {
          margin-top: 40px;
        }
        .iphone {
          margin-top: 30px;
        }
      }
      .right {
        height: auto;
        padding-top: 0;
        padding-bottom: 20px;
        & > ul {
          display: none;
        }
        .zhedie {
          display: block;
          width: 120px;
          height: auto;
          overflow: hidden;
          margin: 0 auto;
          margin-top: 30px;
          .zhedie_div {
            margin-bottom: 15px;
          }
          .last_zhedie_div {
            margin-bottom: 0;
          }
          .el-collapse {
            border: none;
          }
          .el-collapse-item__wrap {
            background-color: #333333;
            color: #fff;
            border: none;
          }
          .el-collapse-item__header {
            background-color: #333333;
            border: none;
            color: #fff;
          }
          .el-collapse-item__content {
            color: #fff;
          }
        }
      }
    }
  }
}
</style>