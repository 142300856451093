import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductCenter.vue')
  },
  {
    path: '/productDetail',
    name: 'product_detail',
    component: () => import(/* webpackChunkName: "about" */ '../components/Product/detail.vue')
  },
  {
    path: '/download',
    name: 'download',
    component: () => import(/* webpackChunkName: "about" */ '../views/DownloadCenter.vue')
  },
  {
    path: '/downloadDetail',
    name: 'download_detail',
    component: () => import(/* webpackChunkName: "about" */ '../components/Download/detail.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsAnnouncement.vue')
  },
  {
    path: '/newsDetail',
    name: 'news_detail',
    component: () => import(/* webpackChunkName: "about" */ '../components/News/detail.vue')
  },
  {
    path: '/questionDetail',
    name: 'question_detail',
    component: () => import(/* webpackChunkName: "about" */ '../components/Question/detail.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactUs.vue')
  },
]

const  scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition && to.meta.keepAlive) {
    return savedPosition;
  }
  return { x: 0, y:0 };
}

const router = new VueRouter({
  scrollBehavior: () => {y: 0},
  routes,
})

export default router
